<template>
    <div>
        <app-page-header></app-page-header>
        <v-container grid-list-xl>
            <!-- kriterij iskanja -->
            <v-layout row wrap align-center justify-center fill-height>
                <v-flex xs12 sm12 md12 lg12 xl12>
                    <business-premise-map></business-premise-map>

                    <div style="min-height:35px;"></div>

                    <v-card>
                            <v-card-title>
                                    <span class="subtitle-1"><v-icon left>mdi-filter</v-icon> Filter results</span>
                                    <v-spacer></v-spacer>
                                    <v-btn icon @click="card1 = !card1">
                                        <v-icon>{{ card1 ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
                                    </v-btn>
                            </v-card-title>
                            <v-divider v-show="card1"></v-divider>
                            <v-card-text v-show="card1">
                                <!-- sales locations -->
                                <v-container grid-list-xl text-x-center align-center class="container--filters">
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs12 md4 align-content-center>
                                            <v-subheader class="pt-7 pl-0"><strong>Sales locations</strong></v-subheader>
                                        </v-flex>
                                        <v-flex xs12 md8 align-content-center>
                                            <v-select
                                            v-model="selected_locations"
                                            :items="locations"
                                            label="Select locations"
                                            multiple
                                            chips
                                            clearable
                                            deletable-chips
                                            prepend-icon="mdi-monitor-star"
                                            item-value="cashiers"
                                            item-text="name"
                                            return-object
                                            dense
                                            no-data-text="No data to display"
                                        ></v-select>   
                                        </v-flex>
                                    </v-layout>
                                </v-container>

                                <!-- cashiers -->
                                <v-container grid-list-xl text-x-center align-center class="container--filters">
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs12 md4 align-content-center>
                                            <v-subheader class="pt-7 pl-0"><strong>Cashiers</strong></v-subheader>
                                        </v-flex>
                                        <v-flex xs12 md8 align-content-center>
                                            <v-select
                                            v-model="selected_cashiers"
                                            :items="cashiers"
                                            label="Select cashiers"
                                            multiple
                                            chips
                                            clearable
                                            deletable-chips
                                            prepend-icon="mdi-monitor-star"
                                            item-value="id"
                                            item-text="name"
                                            return-object
                                            dense
                                            no-data-text="No data to display"
                                        ></v-select>   
                                        </v-flex>
                                    </v-layout>
                                </v-container>

                                <!-- alarm -->
                                <v-container grid-list-xl text-x-center align-center class="container--filters">
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs12 md4 align-content-center>
                                            <v-subheader class="pt-6 pl-0"><strong>Alarm</strong></v-subheader>
                                        </v-flex>
                                        <v-flex xs12 md8 align-content-center>
                                            <v-checkbox @change="displayResults()" class="ti" v-model="active_alarm" :label="`Active alarm only: ${activeAlarmLabel}`"></v-checkbox>
                                        </v-flex>
                                    </v-layout>
                                </v-container>

                                <!-- offline devices -->
                                <v-container grid-list-xl text-x-center align-center class="container--filters">
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs12 md4 align-content-center>
                                            <v-subheader class="pt-6 pl-0"><strong>Offline devices (no response for more than 15 minutes)</strong></v-subheader>
                                        </v-flex>
                                        <v-flex xs12 md8 align-content-center>
                                            <v-checkbox @change="displayResults()" class="ti" v-model="offline_device" :label="`Offline devices: ${offlineDeviceLabel}`"></v-checkbox>
                                        </v-flex>
                                    </v-layout>
                                </v-container>

                                <!-- warning (low paper level, voucher in ticket slot, ... )-->
                                <!--
                                <v-container grid-list-xl text-x-center align-center class="container--filters">
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs12 md4 align-content-center>
                                            <v-subheader class="pt-6 pl-0"><strong>Warning (low paper level, voucher in ticket slot, etc.)</strong></v-subheader>
                                        </v-flex>
                                        <v-flex xs12 md8 align-content-center>
                                            <v-checkbox @change="displayResults()" class="ti" v-model="warning" :label="`Warning: ${warningLabel}`"></v-checkbox>
                                        </v-flex>
                                    </v-layout>
                                </v-container>
                                -->

                            </v-card-text>
                            <v-divider v-show="card1"></v-divider>
                            <span v-if="card1">
                                <v-card-text v-if="$vuetify.breakpoint.xsOnly">
                                <v-btn   @click="search()" color="secondary" dark  block  style=""><v-icon left>mdi-magnify</v-icon>Search</v-btn><br>
                                <v-btn   @click="exportData()" color="#5E35B1" dark  block  style=""><v-icon left>mdi-export</v-icon>Export&nbsp;</v-btn>
                                </v-card-text>
                                <v-card-text v-else>
                                    <v-btn  @click="search()" color="secondary" dark small style="margin-right:10px;"><v-icon left>mdi-magnify</v-icon>Search</v-btn>
                                    <v-btn  @click="exportData()" color="#5E35B1" dark small><v-icon left>mdi-export</v-icon>Export&nbsp;</v-btn>
                                </v-card-text>
                            </span>
                        </v-card>
                </v-flex>
            </v-layout>

            <br>

            <!-- telemetry data table -->
            <!--<br>-->
            <telemetry-data-table
            :settings="dataTableSettings"
            :queryParameters="queryParameters"
            ></telemetry-data-table> 

            <data-table-toggle-columns
            :dataTableId="dataTableSettings.id"
            :columns="dataTableSettings.headers"
            dataTableColumnsMenuId="toggle-telemetry-list-columns"

            ></data-table-toggle-columns>
        </v-container>
    </div>
</template>

<script>
import { EventBus } from '@/main.js'
import { getCurrentDate, getCurrentTime, formatDate, getAppSettingsFromLocalStorage } from '@/helpers/utilities'
import { isMobileDevice } from '@/helpers/utilities.js'
import { addExportToQueue } from '@/helpers/services.js'
const TelemetryDataTable = () => import('@/components/TelemetryDataTable.vue')
const DataTableToggleColumns = () => import('@/components/DataTableToggleColumns.vue')
const BusinessPremiseMap = () => import('@/components/BusinessPremiseMap.vue')

export default {
    components:{
        TelemetryDataTable,
        DataTableToggleColumns,
        BusinessPremiseMap
    },


    data: () => ({
        card1: true,
        card2: true,
        card3: true,

        interval1: null,

        cashiers: [],
        locations: [],

        selected_cashiers: [],
        selected_locations: [],

        active_alarm: true,
        offline_device: false,
        warning: false,

        dataTableSettings: {
            id: 'dtTelemetry',
            title: 'Cashiers statuses',
            sortColumn: ['id'],
            sortDirection: ['desc'],
            progressHeight: 3,
            headers: [
                { text: "ID", value: "id", class: "text-xs-left", visibility: true },
                //{ text: "UUID", value: "uuid", class: "text-xs-left", visibility: true },
                { text: "Sales location", value: "business_premise_name", class: "text-xs-left", visibility: true, sortable: true },
                { text: "Cashier", value: "cashier_nr", class: "text-xs-left", visibility: true, sortable: true },
                { text: "Alarm", value: "errors_str", class: "text-xs-left", visibility: true, sortable: false },
                //{ text: "Customer entrances", value: "ecust", class: "text-xs-left", visibility: true, sortable: true  },
                //{ text: "Staff entrances", value: "epers", class: "text-xs-left", visibility: true , sortable: true },
                //{ text: "Traffic", value: "coins", class: "text-xs-left", visibility: true , sortable: true },
                { text: "Coin exchanger sum", value: "chng", class: "text-xs-left", visibility: true , sortable: true },
                { text: "CE last empty", value: "oldchng", class: "text-xs-left", visibility: true , sortable: true },
                { text: "Paper level", value: "pfill", class: "text-xs-left", visibility: true , sortable: true },
                { text: "Timestamp", value: "last_update", class: "text-xs-left", align: "left", visibility: true , sortable: true },
                { text: "Actions", value: "action", align: "right", class: "text-xs-right", sortable: false, visibility: true}
            ],
            updateInterval: true,
            rowsPerPage: 100,
            endpoint: 'v1/telemetry',
            editItem: {
                route: null,
                routeParameterValue: null
            },
            deleteItem: {
                vuexAction: null,
                replaceStrWithColumnValue: null,
                confirmationText : null,
                dialogConfirmSuccessText: null,
                dialogConfirmFailText: null
            },

            toolbar: {
                buttons: {
                    refresh: true,
                    export: false,
                    addNew: false,
                    addNewRoute: '',
                    toggleColumns: true
                }
            },

            eventBus: {
                refreshDataTable: 'refresh_dt_telemetry',
                totalItems : {
                    event: ''
                },
                search: 'dt-telemetry-search',
                toggleColumnsId: 'toggle-telemetry-list-columns'
            }


        }
    }),

    watch: {
        selected_locations(value) {
            //window.console.log(value)
        },

        selected_cashiers(value) {
            //window.console.log(value)

        },

        active_alarm(value) {
            if(value === true) {
                this.warning = false
            }
        },

        warning(value) {
            if(value === true) {
                this.active_alarm = false
            }
        },

        card1: {
            handler(value) {
                //window.console.log("Telemetry card1: " + value)
                this.$store.commit("APP_SETTING", {
                    setting: "telemetry",
                    key: "filter_results_open",
                    value: value
                })
            },

            //immediate: true
        }
    },

    computed: {
         columnBinding() {
            const binding = {}

            if (this.$vuetify.breakpoint.mdAndDown) binding.column = true

            return binding
        },

        queryParameters() {

            let parameters = {
                selectedCashiers: this.selected_cashiers.map(item => item.uuid),
                selectedLocations: this.selected_locations.map(item => item.id),
                activeAlarm: this.active_alarm,
                offlineDevice: this.offline_device
            }

            
            let selectedLocationCashiers = [];
            if(this.selected_locations.length > 0) {
                this.selected_locations.forEach(selectedLocation => {
                    selectedLocation.cashiers.forEach(cashier => {
                        selectedLocationCashiers.push(cashier.uuid)
                    })
                })
            }

            parameters.selectedLocationCashiers = selectedLocationCashiers;
            
            return parameters;
        },

        activeAlarmLabel() {
            if(this.active_alarm) {
                return "Yes"
            }

            return "No"
        },

        offlineDeviceLabel() {
            if(this.offline_device) {
                return "Yes"
            }

            return "No"
        },

        warningLabel() {
            if(this.warning) {
                return "Yes"
            }

            return "No"
        }
    },

    methods: {
        search() {
            let vm = this
            setTimeout(() => {
                EventBus.$emit(this.dataTableSettings.eventBus.refreshDataTable, vm.queryParameters);
            }, 250)
        },

        exportData() {
            window.console.log("exporting data ...");
            let parameters = {
                selectedCashiers: this.selected_cashiers.map(item => item.uuid)
            }

            let selectedLocationCashiers = [];
            if(this.selected_locations.length > 0) {
                this.selected_locations.forEach(selectedLocation => {
                    selectedLocation.cashiers.forEach(cashier => {
                        selectedLocationCashiers.push(cashier.uuid)
                    })
                })
            }

            parameters.selectedLocationCashiers = selectedLocationCashiers;
            
            addExportToQueue('telemetry', parameters);
        },

        displayResults() {
            let vm = this
            setTimeout(() => {
                EventBus.$emit(this.dataTableSettings.eventBus.refreshDataTable, vm.queryParameters);
            }, 280)
        },

        setParametersData() {
            var vm = this
            vm.$store.commit('SET_PROGRESS', true)
            vm.$store.dispatch('PARAMETERS_DATA')
            .then(response => {
                //console.log(response)
                //console.log(response.data)
                this.locations = response.data.businessPremises
                this.cashiers = response.data.cashiers

                vm.displayResults();
            })
            .catch(error => {
                console.error("### setParametersData ###");
                console.log(error)
            })
            .finally(() => {
                setTimeout(() => {
                    vm.$store.commit('SET_PROGRESS', false)  
                }, 700)
            })
        },

        setCashierLocation() {

            this.selected_locations.forEach(location => {

            })
        }
    },

    created() {
        var vm = this
        this.$store.dispatch('ROUTE_NAME', this.$route.name)   

        let ipp = getAppSettingsFromLocalStorage({
            setting: "dt_telemetry",
            key: "items_per_page"
        })

        if(ipp !== null) {
            this.dataTableSettings.rowsPerPage = ipp
        }

        /*if(isMobileDevice(this)) {
            this.card1 = false
        }*/

        this.setParametersData();

        this.interval1 = setInterval(() => {
            vm.setParametersData()
        }, process.env.VUE_APP_DATA_TABLES_REFRESH_INTERVAL || 180000)


        //window.console.log(vm.$store.getters.appSettings)
        vm.card1 = vm.$store.getters.appSettings.telemetry.filter_results_open

        //window.console.log("Cashiers data: ");
        /*this.$store.dispatch('CASHIERS')
        .then(response => {
            window.console.log(response.data)
        })
        .catch(err => {
            window.console.log("### ERROR CASHIERS ###")
            window.console.log(err)
        })
        */
    },

    mounted() {

    },

    beforeDestroy() {
        EventBus.$off(this.dataTableSettings.id)
        EventBus.$off(this.dataTableSettings.eventBus.refreshDataTable)
        EventBus.$off(this.dataTableSettings.eventBus.search)

        this.$store.commit('SET_SEARCH', '');
        this.$store.commit('SET_SEARCH_FIELD_STATE', false);
        this.$store.commit('SET_MOBILE_SEARCH', false)

        clearInterval(this.interval1)
    }


}

</script>

<style scoped>

</style>